<template>
  <div class="position-relative">
    <div v-for="(value, key) in reorderedSections" :key="key">
      <CollapsableSection
        v-if="sectionHasItems(key, value)"
        class="mb-4"
        :count="value.length"
        :title="titleGetter(key, value)"
      >
        <div v-if="key === 'rooms'">
          <vertical-social-space-chatroom-card
            :id="socialspace.id"
            :chatrooms="value"
            :status="chatroomStatus"
            :muted="mutedStatus"
            :lastmsg="chatroomLastMsg"
            :unread="chatUnreadCounts"
            :member-count="chatroomMemberCounts"
            @change-tab="changeTab"
          />
        </div>
        <div v-else-if="key === 'character_worlds'">
          <world-char-card-horizontal
            :id="socialspace.id"
            :characters="charlistings"
            :privacy="socialspace.privacy"
            :worldstatus="status"
            @added="addedchar"
          ></world-char-card-horizontal>
        </div>
        <div v-else-if="key === 'visual_collections_page'">
          <InlineGallery :is-editor="false" parent-type="world" :parent-id="socialspace?.id" />
        </div>
        <div v-else-if="key === 'description'">
          <ContentSeeMore :text="value" />
        </div>
        <div v-else-if="value.type === 'text' || isHtml(value)">
          <ContentSeeMore :text="value.body || value" />
        </div>
      </CollapsableSection>
    </div>
  </div>
</template>

<script lang="ts" setup>
import InlineGallery from '@/shared/components/Gallery/InlineGallery.vue';
import CollapsableSection from './CollapsableSection.vue';
import { ImageCollection, SocialSpace } from '@/shared/types/static-types';

import { possibleExtraSectionsLabelMap, possibleSectionsLabelMap } from '@/shared/statics/socialspace';
import { unreorderableCreatorKeys, unreorderablesocialspaceKeys } from '@/shared/statics/constants';
import WorldCharCardHorizontal from '@/shared/components/WorldCharCardHorizontal.vue';
import StoryWorldLocationsCard from '@/shared/components/StoryWorldLocationsCard.vue';
import ContentSeeMore from '@/shared/components/ContentSeeMore.vue';
import VerticalSocialSpaceChatroomCard from '@/shared/components/socialSpaceChatroom.vue';

import {
  getChatroomLastMsg,
  getChatroomMemberCounts,
  getChatroomStatus,
  getchatUnreadCounts,
  getUnmuteAndMuteNotificationStatus,
} from '@/shared/actions/socialSpaceChatroom';

const props = defineProps({
  socialspace: {
    type: Object,
    default: {},
  },
  status: {
    type: Object,
    default: { status: '', role: '' },
  },
});

const socialspace = toRef(props, 'socialspace');
const chatroomMemberCounts = ref({});
const mutedStatus = ref({});
const chatroomStatus = ref({});
const status = toRef(props, 'status');
const chatroomStatusRole = ref({});
const chatroomLastMsg = ref({});
const chatUnreadCounts = ref({});
const emits = defineEmits(['changeTab', 'added']);

const titleGetter = (key: string, value: any) => {
  return possibleSectionsLabelMap[key] || value.title || '' || possibleExtraSectionsLabelMap[key];
};

const addedchar = () => {
  emits('added');
};

const sectionHasItems = (key: any, value: any) => {
  if (key === 'extra') return false;
  if (key === 'about' && !value) return false;
  if (status.value.status == 'member' && key === 'character_worlds') return true;
  if (key === 'visual_collections_page' && isEmpty(value.results)) return false;
  if (!value) return false;
  if (!Array.isArray(value) && typeof value !== 'object' && value) return true;
  if (Array.isArray(value) && value.length) return true;
  if (typeof value === 'object' && Object.keys(value).length > 0) {
    if (value.type === 'text' && !value.body) {
      return false;
    }
    return true;
  }

  return false;
};

const possibleSections = computed(() => {
  return omit(socialspace?.value, unreorderablesocialspaceKeys as string[]);
});

const charlistings = computed(() => {
  return socialspace.value.character_worlds;
});

const hasValue = (value: any, key: any) => {
  if (key === 'extra') return false;
  return value || value === '';
};

const changeTab = (value: string) => {
  emits('changeTab', value);
};

const reorderedSections = computed(() => {
  // Move description to top in default ordering
  const defaultPossibleSections = Array.from(Object.keys(possibleSections.value));
  const index = defaultPossibleSections.indexOf('description');
  defaultPossibleSections.unshift(defaultPossibleSections.splice(index, 1)[0]);

  const order: string[] = socialspace?.value?.extra.order || defaultPossibleSections;
  const reorderedSections = order.reduce((acc: SocialSpace, current) => {
    if (!hasValue(possibleSections.value[current], '') && !hasValue(possibleSections.value.extra[current], '')) {
      return acc;
    }
    return {
      ...acc,
      [current]: !hasValue(possibleSections.value[current], '')
        ? possibleSections.value.extra[current]
        : possibleSections.value[current],
    };
  }, {} as SocialSpace);
  return reorderedSections;
});

const reOrderdedExtraSections = computed(() => {
  return omit(reorderedSections.value.extra, 'quote', 'order');
});
const visualCollections = computed(() => {
  return socialspace?.value?.visual_collections_page?.results || [];
});

const populatedVisualCollections = computed(() => {
  return visualCollections.value.filter((coll: ImageCollection) => coll?.images_page?.count || 0) || [];
});

const preselectedAlbum = computed(() => {
  return populatedVisualCollections.value.length > 0 ? 0 : null;
});

const fetchChatroomStatus = async () => {
  const resp = await getChatroomStatus(socialspace.value.id, map(socialspace.value.rooms, 'id') as string[]);
  chatroomStatus.value = keyBy(resp, 'room');
};

const fetchChatroomLastMsg = async () => {
  const resp = await getChatroomLastMsg(socialspace.value.id, map(socialspace.value.rooms, 'id') as string[]);
  chatroomLastMsg.value = keyBy(resp, 'room');
};
const fetchchatUnreadCounts = async () => {
  chatUnreadCounts.value = {};
  const resp = await getchatUnreadCounts(map(socialspace.value.rooms, 'id') as string[]);
  chatUnreadCounts.value = keyBy(resp, 'room');
};

const fetchChatroomMemberCounts = async () => {
  const resp = await getChatroomMemberCounts(socialspace.value.id, map(socialspace.value.rooms, 'id') as string[]);
  chatroomMemberCounts.value = keyBy(resp, 'room');
};
const fetchMutedAndUmuteStatus = async () => {
  const resp = await getUnmuteAndMuteNotificationStatus(map(socialspace.value.rooms, 'id') as string[]);
  mutedStatus.value = keyBy(resp, 'room');
};
const isHtml = (content: string) => {
  return `${content}`.includes('<');
};

const updateImage = (data: any, options: { hasActiveAlbum: boolean; activeAlbum: number | null }) => {
  for (const col of populatedVisualCollections.value) {
    const imgIndex = col.images_page!.results.findIndex((res: any) => res.id === get(data, 'image.id'));
    col.images_page!.results[imgIndex] = {
      ...data.image,
      ...get(data.reactResp, 'updatedReactionsData', {}),
    };
  }
};

onMounted(() => {
  if (socialspace.value) {
    fetchChatroomStatus();
    fetchChatroomLastMsg();
    fetchChatroomMemberCounts();
    fetchchatUnreadCounts();
    fetchMutedAndUmuteStatus();
  }
});
</script>

<style lang="sass" scoped></style>
