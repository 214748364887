<template>
  <ion-page class="page scrollable social-space-page position-relative" style="margin-top: -38px">
    <Transition>
      <div v-if="loading" class="loading-content position-absolute">
        <HeaderLoading />
      </div>
      <div v-else-if="socialSpace" class="content">
        <social-space-header
          :socialspace="socialSpace"
          :status="status"
          :counts="requestCounts"
          @updated="fetchSocialSpaceMemberStatus"
        />
        <social-space-tabs class="mt-5" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
        <social-space-information
          v-if="currentTab === 'information'"
          class="px-3"
          :socialspace="socialSpace"
          :sections-collapsed="sectionsCollapsed"
          :has-sections="hasSections"
          :status="status"
          @change-tab="changeTab"
          @added="loadSocialSpaceBySlug(slug)"
        />
        <social-space-chatroom class="px-3" v-else :id="socialSpace.id" :rooms="socialSpace.rooms" />
      </div>
    </Transition>
  </ion-page>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import SocialSpaceHeader from './components/SocialSpaceHeader.vue';
import SocialSpaceInformation from './components/SocialSpaceInformation.vue';
import SocialSpaceChatroom from './components/SocialSpaceChatroom.vue';
import SocialSpaceTabs from './components/SocialSpaceTabs.vue';
import HeaderLoading from './components/SocialSpaceHeaderLoading.vue';
import { socialSpacesTabs } from '@/shared/statics/tabs';

import { SocialSpace, Tab } from '@/shared/types/static-types';
import { socialSpaceStore } from '@/shared/pinia-store/socialspace';
import { unreorderablesocialspaceKeys } from '@/shared/statics/constants';
import { getMemberRequestCounts, getMemberstatus, getWorldsMemberCounts } from '@/shared/actions/worlds';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';

const tabs = ref(socialSpacesTabs);
const currentTab = ref('information');
const tabIndex = ref(0);
const { isAuthenticated, user: loggedInUser } = authStore();

const router = useRouter();
const { socialSpace, loading, loadSocialSpaceBySlug, sectionsCollapsed } = socialSpaceStore();
const route = useRoute();

const memberCount = ref(0);
const status = ref({ role: '', status: '' });
const requestCounts = ref({});

const picture = ref('');
const title = ref('');
const description = ref('');
const pageTitle = ref('');
const allowCrawlers = ref(true);
const url = ref(`https://characterhub.com${route.path}`);

const possibleSections = computed(() => {
  return omit(socialSpace?.value, unreorderablesocialspaceKeys as string[]);
});

const fetchSocialSpaceMemberStatus = async () => {
  if (!isAuthenticated.value) return;
  const response = await getMemberstatus({ world_id: socialSpace.value?.id });
  status.value = response;
  if (status.value.role === 'leader') fetchRequestCount();
};
const changeTab = ({ value }: Tab) => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === value)
  );
  if (tIndex !== -1) {
    tabIndex.value = tIndex;
    currentTab.value = value;
  }
};

const fetchSocialSpacesMemberCounts = async () => {
  const resp = await getWorldsMemberCounts([socialSpace.value?.id || '']);
  memberCount.value = get(resp[0], 'member_count') || 0;
};

const fetchRequestCount = async () => {
  try {
    let response;
    response = await getMemberRequestCounts(socialSpace.value!.id);

    requestCounts.value = response;
  } catch (e) {
    await toast.show('Could not load. Please try again.', 'nonative', 'danger');
  }
};

const reorderedSections = computed(() => {
  const order: string[] = socialSpace?.value?.order || Object.keys(possibleSections.value);
  const reorderedSections = order.reduce((acc: SocialSpace, current) => {
    return {
      ...acc,
      [current]: possibleSections.value[current],
    };
  }, {} as SocialSpace);
  return reorderedSections;
});

const hasSections = computed(() => {
  return Object.keys(reorderedSections.value).length > 0;
});

const slug = computed(() => {
  return route.params.slug as string;
});

const triggerWarning = async () => {
  if (
    socialSpace.value &&
    loggedInUser.value.id !== socialSpace.value.user?.id &&
    !isEmpty(socialSpace.value.triggerwarning)
  ) {
    const alert = await alertController.create({
      cssClass: 'char-warning-alert',
      backdropDismiss: false,
      header: 'Warning',
      message: socialSpace.value.triggerwarning,
      translucent: false,
      buttons: [
        {
          text: 'Go Back',
          handler: () => {
            router.go(-1);
          },
        },
        { text: 'Continue' },
      ],
    });
    await alert.present();

    await alert.onDidDismiss();
  }
};

const fetchSpaceData = async () => {
  const { slug } = route.params;
  const slugAsString = slug as string;
  await loadSocialSpaceBySlug(slugAsString);
  await fetchSocialSpacesMemberCounts();

  pageTitle.value = `${socialSpace.value?.name} - CharacterHub`;
  description.value = `Read more about ${socialSpace.value?.name} on CharacterHub!`;
  title.value = `${socialSpace.value?.name}`;
  picture.value = socialSpace.value?.cropped_profile_img || socialSpace?.value?.cover_image || '';
  allowCrawlers.value = socialSpace?.value?.privacy === 'P' && memberCount.value > 200;

  useChHead(pageTitle, title, description, url, picture, allowCrawlers);

  if (socialSpace.value?.type === 'story') router.replace({ name: 'world-details', params: { slug } });

  if (process.client) {
    await triggerWarning();
    fetchSocialSpaceMemberStatus();
  }
};

await useChAsyncData(fetchSpaceData);

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  router.replace({ name: 'social-space-details', query: { tab: value } });
};

onBeforeMount(() => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === route.query.tab)
  );
  if (route.query.tab && tIndex !== -1) {
    tabIndex.value = tIndex;
    currentTab.value = route.query.tab as string;
  }
});
</script>
<style lang="sass" scoped>
.loading-content
  width: calc(100% - 1.5rem)
.social-space-page
  overflow: hidden
  padding: 24px 0 !important
  margin-top: -62px !important
  min-height: 628px
.v-enter-active,
.v-leave-active
  transition: opacity 0.5s ease

.v-enter-from,
.v-leave-to
  opacity: 0
</style>
